<template>
  <div class="flex-container">
    <v-dialog width="30vw" v-model="editDialogIsOpen">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="ml-3 mr-3" v-bind="attrs" v-on="on">
          <v-icon color="primary">
            mdi-pencil-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Person bearbeiten
        </v-card-title>
        <v-card-text>
          <v-row class="ps-3 mt-3 mb-3" :ref="'test'">
            <v-form :ref="'form'">
              <v-text-field
                label="Name"
                :rules="nameInputRules"
                v-model="nameInput"
              ></v-text-field>
              <v-text-field
                label="Telefonnummer"
                v-model="phoneNumberInput"
                :rules="phoneNumberRules"
                @keydown.space.prevent
              />
            </v-form>
          </v-row>
          <v-row class="ps-3 mt-7 ">
            <v-btn @click="closeEditDialog">
              Abbrechen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="updatePerson">
              Speichern
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="30vw" v-model="deletionDialogIsOpen">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="ml-3 mr-3" v-bind="attrs" v-on="on">
          <v-icon color="primary"> mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Person Löschen
        </v-card-title>
        <v-card-text>
          <v-row class="ps-3 mt-3 mb-3">
            Möchten Sie den Benutzer
            {{ personToEdit.name }}
            wirklich löschen?
          </v-row>
          <v-row class="ps-3 mt-7 ">
            <v-btn @click="closeDeleteDialog">
              Abbrechen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="deletePerson">
              Löschen
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { isNotEmpty, isPhoneNumber } from "../service/validationService";

export default {
  name: "edit-buttons",
  props: {
    personToEdit: {
      type: Object,
      required: true
    }
  },
  computed: {
    nameInputRules() {
      return [isNotEmpty];
    },
    phoneNumberRules() {
      return [isNotEmpty, isPhoneNumber];
    },
    isInputValid() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      } else {
        return false;
      }
    }
  },
  watch: {
    editDialogIsOpen(newValue) {
      if (newValue) {
        this.nameInput = this.personToEdit.name;
        this.phoneNumberInput = this.personToEdit.number;
      }
    }
  },
  data() {
    return {
      editDialogIsOpen: false,
      deletionDialogIsOpen: false,
      nameInput: "",
      phoneNumberInput: ""
    };
  },
  methods: {
    closeDeleteDialog() {
      this.deletionDialogIsOpen = false;
    },
    closeEditDialog() {
      this.editDialogIsOpen = false;
    },
    updatePerson() {
      if (this.isInputValid) {
        this.$store
          .dispatch("updatePerson", {
            personId: this.personToEdit.id,
            name: this.nameInput,
            number: this.phoneNumberInput
          })
          .then(() => {
            this.closeEditDialog();
          });
      }
    },
    deletePerson() {
      this.$store
        .dispatch("deletePerson", { personId: this.personToEdit.id })
        .then(() => {
          this.closeDeleteDialog();
        });
    }
  }
};
</script>
<style>
.flex-container {
  display: flex;
  justify-content: flex-end;
}
</style>
