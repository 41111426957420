<template>
  <v-container>
    <v-row class="ma-4">
      <h1>
        {{ regionName }}
      </h1>
    </v-row>
    <v-row class="ma-4">
      <v-row>
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="items[0]">
            <v-card flat>
              <on-call-tab></on-call-tab>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="items[1]">
            <v-card flat width="70vw">
              <person-tab />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import OnCallTab from "../components/OnCallTab";
import PersonTab from "../components/PersonTab";
import { getRegionName } from "@/helper/region.helper";

export default {
  name: "GenericRegionView",
  components: { PersonTab, OnCallTab },
  computed: {
    region() {
      return this.$route.params.id;
    },
    regionName() {
      return getRegionName(this.region);
    }
  },
  methods: {
    fetchRegion(regionNumber) {
      this.$store.commit("setRegion", regionNumber);
      this.$store.dispatch("fetchRegion");
    }
  },
  mounted() {
    this.$store.commit("setRegion", this.region);
    this.$store.dispatch("fetchRegion");
  },
  data() {
    return {
      tab: null,
      items: ["Schichten", "Personen"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  watch: {
    region(newRegion) {
      this.fetchRegion(newRegion);
    }
  }
};
</script>
