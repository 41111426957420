<template>
  <v-dialog width="30vw" v-model="isOpen">
    <v-card>
      <v-card-title>
        Schicht Hinzufügen
      </v-card-title>
      <v-card-text>
        <v-row class="ps-3 mt-3 mb-3" :ref="'test'">
          <v-form :ref="'form'">
            <v-date-picker
              v-if="isOpen"
              v-model="dateRange"
              :allowed-dates="isAllowed"
              range
              locale="de-ch"
            ></v-date-picker>
            <v-autocomplete
              v-model="onCallPersonId"
              :items="people"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </v-form>
        </v-row>
        <v-row class="ps-3 mt-7 ">
          <v-btn @click="closeCreateDialog">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="createOnCall"
            :loading="isLoading"
            :disabled="isInputIncomplete"
          >
            Speichern
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "create-on-call",
  props: {
    createDialogIsOpen: {
      type: Boolean,
      default: false
    },
    people: {
      type: Array,
      default: () => []
    },
    startDate: {
      type: String,
      default: ""
    },
    allowedDates: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      onCallPersonId: undefined,
      dateRange: [],
      isLoading: false,
      isOpen: false
    };
  },
  watch: {
    startDate(newVal) {
      this.dateRange = [];
      this.dateRange.push(newVal);
    },
    createDialogIsOpen(newVal) {
      this.isOpen = newVal;
      if (newVal === false) {
        this.closeCreateDialog();
      }
    },
    isOpen(newVal) {
      if (newVal === false) {
        this.closeCreateDialog();
      }
    }
  },
  computed: {
    isInputIncomplete() {
      return !this.dateRange.length > 0 || !this.onCallPersonId;
    }
  },
  methods: {
    closeCreateDialog() {
      this.$emit("closed");
      this.onCallPersonId = "";
    },
    isAllowed(val) {
      return this.allowedDates.includes(val);
    },
    createOnCall() {
      this.isLoading = true;
      if (this.dateRange.length === 1) {
        this.dateRange.push(this.dateRange[0]);
      }
      this.$store
        .dispatch("createOnCall", {
          startDate: this.dateRange[0],
          endDate: this.dateRange[1],
          personId: this.onCallPersonId
        })
        .then(() => {
          this.isLoading = false;
          this.closeCreateDialog();
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
