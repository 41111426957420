<template>
  <v-card color="grey lighten-4" min-width="350px" flat>
    <v-toolbar dark>
      <v-btn icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-toolbar-title> Bearbeiten</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-autocomplete
        v-model="currentPerson"
        :items="people"
        item-text="name"
        item-value="id"
      >
      </v-autocomplete>
      <v-checkbox v-model="editAll" label="Gesamte Schicht bearbeiten" />
    </v-card-text>
    <v-card-actions>
      <v-btn text color="secondary" @click="closeDialog">
        Abbrechen
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="warning" @click="commitDelete">
        Löschen
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="commitChanges">
        Speichern
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "edit-on-call-popup",
  data() {
    return {
      editAll: false,
      currentPerson: null
    };
  },
  mounted() {
    this.currentPerson = this.personToChange;
  },
  watch: {
    personToChange(newVal) {
      this.currentPerson = newVal;
    }
  },
  props: {
    personToChange: {
      type: Number,
      required: true
    },
    people: {
      type: Array,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
      this.currentPerson = -1;
    },
    commitChanges() {
      this.$emit("save", { person: this.currentPerson, editAll: this.editAll });
      this.closeDialog();
    },
    commitDelete() {
      this.$emit("delete", { editAll: this.editAll });
      this.closeDialog();
    }
  }
};
</script>
