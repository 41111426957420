import Vue from "vue";
import Vuex from "vuex";
import {
  deletePerson,
  getAllOnCall,
  getAllPerson,
  updateOnCall,
  updatePerson,
  createPerson,
  deleteOnCall
} from "@/service/http/http.js";
import generateEventsFromData from "../service/generateEventsFromData";
import { getNotGivenPerson } from "@/service/generateEventsFromData";
import { createOnCall } from "@/service/http/http";
import { getWholeOnCall } from "@/service/onCallService";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    onCalls: [],
    people: [],
    events: [],
    regionSelected: ""
  },
  getters: {
    getOnCalls(state) {
      return state.onCalls;
    },
    getPeople(state) {
      return state.people;
    },
    getEvents(state) {
      return state.events;
    },
    getPersonById(state) {
      return id => {
        const person = state.people.filter(person => person.id === id)[0];
        if (person) {
          return person;
        }
        return getNotGivenPerson();
      };
    }
  },
  mutations: {
    setPeople(state, people) {
      state.people = people;
    },
    setOnCalls(state, onCalls) {
      state.onCalls = onCalls;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setRegion(state, region) {
      state.regionSelected = region;
    },
    setUpdatedOnCall(state, updatedOnCall) {
      const index = state.onCalls.findIndex(
        onCall => onCall.id === updatedOnCall.id
      );
      state.onCalls[index] = updatedOnCall;
    }
  },
  actions: {
    fetchRegion(context) {
      return new Promise(resolve => {
        const people = getAllPerson(context.state.regionSelected);
        const onCall = getAllOnCall(context.state.regionSelected);
        Promise.all([people, onCall]).then(data => {
          context.commit("setPeople", data[0]);
          context.commit("setOnCalls", data[1]);
          context.dispatch("generateEventsFromOnCalls");
        });
        resolve();
      });
    },
    fetchPeople(context) {
      return getAllPerson(context.state.regionSelected).then(people => {
        context.commit("setPeople", people);
      });
    },
    generateEventsFromOnCalls(context) {
      const events = generateEventsFromData(
        context.state.onCalls,
        context.state.people
      );
      context.commit("setEvents", events);
    },
    updateOnCall(context, payload) {
      return updateOnCall(
        payload.onCallId,
        payload.personId,
        context.state.regionSelected
      ).then(updatedOnCall => {
        context.commit("setUpdatedOnCall", updatedOnCall);
        context.dispatch("generateEventsFromOnCalls");
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateAllOnCall(context, payload) {
      const wholeList = getWholeOnCall(context.state.onCalls, payload.onCallId);
      const promises = [];
      wholeList.forEach(onCall => {
        promises.push(
          updateOnCall(
            onCall.id,
            payload.personId,
            context.state.regionSelected
          )
        );
      });
      Promise.allSettled(promises).then(() => {
        context.dispatch("fetchRegion");
      });
    },
    deleteAllOnCall(context, payload) {
      const wholeList = getWholeOnCall(context.state.onCalls, payload.onCallId);
      const promises = [];
      wholeList.forEach(onCall => {
        promises.push(deleteOnCall(onCall.id, context.state.regionSelected));
      });
      Promise.allSettled(promises).then(() => {
        context.dispatch("fetchRegion");
      });
    },
    deleteOnCall(context, payload) {
      return deleteOnCall(payload.onCallId, context.state.regionSelected).then(
        () => {
          setTimeout(() => {
            context.dispatch("fetchRegion");
          }, 1000);
        }
      );
    },
    deletePerson(context, payload) {
      return deletePerson(payload.personId, context.state.regionSelected).then(
        () => {
          setTimeout(() => {
            context.dispatch("fetchPeople");
            context.dispatch("generateEventsFromOnCalls");
          }, 1000);
        }
      );
    },
    updatePerson(context, payload) {
      return updatePerson(
        payload.personId,
        payload.name,
        payload.number,
        context.state.regionSelected
      ).then(() => {
        context.dispatch("fetchPeople");
        context.dispatch("generateEventsFromOnCalls");
      });
    },
    createPerson(context, payload) {
      return new Promise((resolve, reject) => {
        createPerson(payload.name, payload.number, context.state.regionSelected)
          .then(() => {
            context.dispatch("fetchPeople");
            context.dispatch("generateEventsFromOnCalls");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    createOnCall(context, payload) {
      return new Promise((resolve, reject) => {
        createOnCall(
          payload.startDate,
          payload.endDate,
          payload.personId,
          context.state.regionSelected
        )
          .then(() => {
            context.dispatch("fetchRegion");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
});
