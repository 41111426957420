<template>
  <h1 class="tenso-logo">
    Tenso
  </h1>
</template>

<script>
export default {
  name: "TensoLogo"
};
</script>

<style scoped>
.tenso-logo {
  border-bottom: 3px solid #1976d2;
  display: inline;
  font-size: xxx-large;
}
h1 {
  font-family: "RobotoThin";
}
</style>
