export default class OnCall {
  constructor(id, date, personId) {
    this.id = id;
    this.date = date;
    this.personId = personId;
  }

  static FROM_BACKEND_DATA(data) {
    return new OnCall(data.id, new Date(data.day), data.personId);
  }
}
