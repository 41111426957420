<template>
  <v-card-text class="ma-0 pa-0 mt-4">
    <seso-alert
      :is-active="isAlertActive"
      :message="alertMessage"
      @reset="isAlertActive = false"
    />
    <v-container class="ma-0 pa-0">
      <v-row class="ma-0 pa-0 flow-right mb-8">
        <v-dialog width="30vw" v-model="createDialogIsOpen">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon left>
                mdi-plus
              </v-icon>
              Neue Person erfassen
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              Person erfassen
            </v-card-title>
            <v-card-text>
              <v-row class="ps-3 mt-3 mb-3" :ref="'test'">
                <v-form :ref="'form'">
                  <v-text-field
                    label="Name"
                    :rules="nameInputRules"
                    v-model="nameInput"
                  ></v-text-field>
                  <v-text-field
                    @keydown.space.prevent
                    :rules="phoneNumberRules"
                    label="Telefonnummer"
                    v-model="phoneNumberInput"
                  />
                </v-form>
              </v-row>
              <v-row class="ps-3 mt-7 ">
                <v-btn @click="closeCreateDialog">
                  Abbrechen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="createPerson"
                  :loading="isLoading"
                >
                  Speichern
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <v-data-table
            class="flex-grow-1"
            locale="de-CH"
            :headers="getHeaders"
            :items="getPeopleForDataTable"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:item.edit="{ item }">
              <edit-buttons :person-to-edit="item" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>
<script>
import { mapGetters } from "vuex";
import EditButtons from "./EditButtons";
import { isNotEmpty, isPhoneNumber } from "@/service/validationService";
import SesoAlert from "@/components/SesoAlert";

export default {
  name: "person-tab",
  components: { SesoAlert, EditButtons },
  computed: {
    ...mapGetters(["getPeople"]),
    nameInputRules() {
      return [isNotEmpty];
    },
    phoneNumberRules() {
      return [isNotEmpty, isPhoneNumber];
    },
    getHeaders() {
      return [
        { text: "Name", value: "name" },
        { text: "Nummer", value: "number" },
        { text: "", value: "edit" }
      ];
    },
    getPeopleForDataTable() {
      const dataForTable = [];
      this.getPeople.forEach(person => {
        dataForTable.push({
          id: person.id,
          name: person.name,
          number: person.number
        });
      });
      return dataForTable;
    }
  },
  data() {
    return {
      createDialogIsOpen: false,
      nameInput: "",
      phoneNumberInput: "",
      data: 0,
      pageCount: 0,
      page: 0,
      isLoading: false,
      isAlertActive: true,
      alertMessage: ""
    };
  },
  methods: {
    closeCreateDialog() {
      this.createDialogIsOpen = false;
      this.isLoading = false;
      this.$refs.form.reset();
    },
    createPerson() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch("createPerson", {
          name: this.nameInput,
          number: this.phoneNumberInput
        })
        .then(() => {
          this.closeCreateDialog();
        })
        .catch(err => {
          this.alertMessage = err.response.data.message;
          this.isAlertActive = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
<style>
.flow-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
