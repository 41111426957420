const colorsForCalendar = [
  "red",
  "pink",
  "purple",
  "deep-purple",
  "indigo",
  "blue",
  "light-blue",
  "cyan",
  "teal",
  "green",
  "light-green",
  "lime",
  "amber",
  "orange",
  "deep-orange",
  "brown",
  "blue-grey",
  "grey"
];

export { colorsForCalendar };
