import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";
import GenericRegionView from "@/views/GenericRegionView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/region/1"
  },
  {
    path: "/region/:id",
    component: GenericRegionView,
    beforeEnter: authGuard
  }
];

const router = new VueRouter({
  routes
});

export default router;
