<template>
  <v-app>
    <navigation-drawer
      :is-open="drawer"
      @close="closeDrawer"
    ></navigation-drawer>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="1"></v-col>
        <v-col cols="10">
          <tenso-logo />
        </v-col>
        <v-col cols="1">
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
            class="ma-3"
          ></v-app-bar-nav-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="1" />
        <v-col cols="10">
          <v-main>
            <router-view />
          </v-main>
        </v-col>
        <v-col cols="1"> </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { getBaseUrl } from "@/service/http/urlService";
import TensoLogo from "@/components/TensoLogo";
import NavigationDrawer from "@/components/NavigationDrawer";

export default {
  name: "App",

  components: { NavigationDrawer, TensoLogo },

  data: () => ({
    drawer: false,
  }),
  methods: {
    closeDrawer() {
      this.drawer = false;
    },
    logout() {
      this.$auth.logout({
        returnTo: getBaseUrl()
      });
    }
  },
};
</script>
<style lang="scss">
.logo-row {
  height: auto;
}
.container {
  margin: 0;
  padding: 0;
  width: 100%;
}

@font-face {
  font-family: "RobotoThin";
  src: url(./assets/Roboto-Light.ttf) format("truetype");
}
h1 {
  font-family: "RobotoThin";
}
</style>
