<template>
  <v-card-text>
    <v-container fluid>
      <v-row no-gutters rows="1" stype="width: 100%">
        <create-on-call
          :create-dialog-is-open="createDialogIsOpen"
          :people="people"
          :start-date="startDateNewOnCall"
          @closed="closeCreateDialog"
          :allowed-dates="invertedOnCalls"
        />
        <v-sheet tile height="54" class="d-flex" width="70vw">
          <v-spacer />
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <h2 class="current-date-string mt-2" @click="setToday">
            {{ currentDateString }}
          </h2>
          <v-spacer />
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer />
        </v-sheet>
        <v-sheet height="600" width="100%">
          <v-calendar
            locale="de"
            ref="calendar"
            v-model="value"
            :events="events"
            color="primary"
            @change="changeCalendarView"
            @click:event="showEvent"
            @click:day="showDate"
            @click:date="showDate"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <edit-on-call-popup
              :people="people"
              :person-to-change="personToChange"
              @delete="deleteOnCall"
              @save="saveChangedOnCall"
              @close="closeChangeDialog"
            />
          </v-menu>
        </v-sheet>
      </v-row>

      <v-row>
        <v-spacer />
        <v-btn
          right
          outlined
          class="mr-4 mt-4"
          color="grey darken-2"
          @click="setToday"
        >
          Heute
        </v-btn>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CreateOnCall from "./CreateOnCall";
import { invertOnCalls } from "@/service/invertOncalls";
import EditOnCallPopup from "@/components/EditOnCallPopup";
import { getMonthFromNumber } from "@/helper/calendar.helper";

export default {
  name: "OnCallTab",
  components: { EditOnCallPopup, CreateOnCall },
  computed: {
    ...mapGetters(["getEvents", "getPeople", "getPersonById", "getOnCalls"]),
    events() {
      return this.getEvents;
    },
    people() {
      return this.getPeople;
    },
    invertedOnCalls() {
      return invertOnCalls(this.getOnCalls);
    }
  },
  data: () => ({
    value: "",
    selectedEvent: {},
    selectedOpen: false,
    selectedElement: null,
    personToChange: -1,
    createDialogIsOpen: false,
    startDateNewOnCall: "",
    currentDateString: ""
  }),
  methods: {
    setToday() {
      this.value = "";
    },
    changeCalendarView(args) {
      this.currentDateString = `${getMonthFromNumber(args.start.month)} ${
        args.start.year
      }`;
      console.log(this.currentDateString);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.setSelected(event.personId);
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    hasEvent(date) {
      const sameEvents = this.events.filter(event => {
        return moment(event.start).isSame(moment(date), "day");
      });
      return sameEvents.length > 0;
    },
    showDate(args) {
      console.log(args);
      if (this.hasEvent(args.date)) {
        throw new Error("Not implemented");
      } else {
        this.startDateNewOnCall = args.date;
        this.createDialogIsOpen = true;
      }
    },
    setSelected(id) {
      this.personToChange = this.getPersonById(id).id;
    },
    saveChangedOnCall(args) {
      if (args.editAll) {
        this.$store
          .dispatch("updateAllOnCall", {
            personId: args.person,
            onCallId: this.selectedEvent.id
          })
          .then(() => {
            this.selectedOpen = false;
          });
      } else {
        this.$store
          .dispatch("updateOnCall", {
            personId: args.person,
            onCallId: this.selectedEvent.id
          })
          .then(() => {
            this.selectedOpen = false;
          });
      }
    },
    closeCreateDialog() {
      this.createDialogIsOpen = false;
      this.startDateNewOnCall = "";
    },
    closeChangeDialog() {
      this.selectedOpen = false;
      this.personToChange = -1;
    },
    deleteOnCall(args) {
      if (args.editAll) {
        this.$store
          .dispatch("deleteAllOnCall", {
            onCallId: this.selectedEvent.id
          })
          .then(() => {
            this.selectedOpen = false;
          });
      } else {
        this.$store
          .dispatch("deleteOnCall", {
            onCallId: this.selectedEvent.id
          })
          .then(() => {
            this.selectedOpen = false;
          });
      }
    }
  }
};
</script>

<style>
.current-date-string {
  font-family: RobotoThin;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
</style>
