import { colorsForCalendar } from "../constants/Colors.const";
import Person from "@/models/Person";

function generateEventsFromData(onCalls, people) {
  const events = [];
  onCalls.forEach(onCall => {
    events.push(generateEventFromOnCall(onCall, people));
  });
  return events;
}

function generateEventFromOnCall(onCall, people) {
  const person = getPersonById(people, onCall.personId)
  return {

    name: person.name,
    start: new Date(onCall.date),
    id: onCall.id,
    personId: person.id,
    color: getPersonColor(person.id)
  };
}

function getPersonColor(personId) {
  if(personId === -1){
    return "black"
  }
  return colorsForCalendar[personId % colorsForCalendar.length];
}

function getPersonById(people, id) {
  const person = people.filter(person => person.id === id)[0];
  if (person) {
    return person;
  }
  return getNotGivenPerson()
}

export function getNotGivenPerson(){
  return new Person(-1, "Nicht vergeben", "");
}
export default generateEventsFromData;
