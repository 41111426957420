export default class Person {
  constructor(id, name, number) {
    this.id = id;
    this.name = name;
    this.number = number;
  }

  static FROM_BACKEND_DATA(data) {
    return new Person(data.id, data.name, data.number);
  }
}
