import moment from "moment";

export function invertOnCalls(onCalls) {
  let onCallMoments = [];
  onCalls.forEach(onCall => {
    onCallMoments.push(moment(onCall.date));
  });
  sortMomentArray(onCallMoments);
  const invertedDates = [];
  const runningMoment = moment().subtract(7, "days");
  const endMoment = moment().add(2, "years");
  while (isFirstElementBefore(onCallMoments, runningMoment)) {
    onCallMoments.shift();
  }
  while (runningMoment.isSameOrBefore(endMoment)) {
    if (isFirstElementAfter(onCallMoments, runningMoment)) {
      invertedDates.push(runningMoment.format("YYYY-MM-DD"));
    } else {
      onCallMoments.shift();
    }
    runningMoment.add(1, "Days");
  }
  return invertedDates;
}

function isFirstElementBefore(momentsArray, moment) {
  return momentsArray.length > 0 && momentsArray[0].isBefore(moment);
}

function isFirstElementAfter(momentsArray, moment) {
  if (momentsArray.length < 1) {
    return true;
  }
  return momentsArray.length > 0 && momentsArray[0].isAfter(moment);
}

function sortMomentArray(momentArray) {
  momentArray.sort((a, b) => a - b);
}
