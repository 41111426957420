<template>
  <div>
    <v-alert
      v-model="alert"
      border="left"
      close-text="Close Alert"
      color="alert"
      dark
      dismissible
    >
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "SesoAlert",
  data() {
    return {
      alert: false
    };
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      default: "Ein Fehler ist aufgetreten"
    }
  },
  watch: {
    isActive(newVal) {
      this.alert = newVal;
    },
    alert(newVal){
      if(newVal){
        this.$emit("reset")
      }
    }
  }
};
</script>

<style scoped></style>
