<template>
  <v-navigation-drawer v-model="isOpen" absolute temporary right>
    <v-list nav dense>
      <v-list-item-group
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item
          v-for="region in regionList"
          :key="region.id"
          @click="
            () => {
              goToRegion(region.id);
            }
          "
        >
          <v-list-item-title>{{ region.name }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-icon left>
            mdi-logout
          </v-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getBaseUrl } from "@/service/http/urlService";
import {getRegionName} from "@/helper/region.helper";

export default {
  name: "NavigationDrawer",
  methods: {
    goToRegion(number) {
      this.$router.push(`/region/${number}`);
      this.closeDrawer();
    },
    logout() {
      this.$auth.logout({
        returnTo: getBaseUrl()
      });
      this.closeDrawer();
    },
    closeDrawer() {
      this.$emit("close");
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    regionList() {
      const numberOfRegions = Number(process.env.VUE_APP_NUMBER_REGIONS);
      const listOfRegions = [];
      for (let index = 1; index <= numberOfRegions; index++) {
        listOfRegions.push({
          id: index,
          name: getRegionName(index)
        });
      }
      return listOfRegions;
    }
  }
};
</script>

<style scoped></style>
