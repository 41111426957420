import {
  createPersonInBackend, deleteOnCallInBackend,
  deletePersonInBackend,
  getAllOnCallFromBackend,
  getAllPeopleFromBackend,
  updateOnCallInBackend,
  updatePersonInBackend,
  createOnCallInBackend
} from "@/service/http/requests";
import { getClientByRegion } from "@/service/http/clients";

async function getAllPerson(region) {
  const regionSpecificClient = getClientByRegion(region);
  return getAllPeopleFromBackend(regionSpecificClient);
}

async function getAllOnCall(region) {
  const regionSpecificClient = getClientByRegion(region);
  return getAllOnCallFromBackend(regionSpecificClient);
}

async function updateOnCall(onCallId, personId, region) {
  const regionSpecificClient = getClientByRegion(region);
  return updateOnCallInBackend(regionSpecificClient, onCallId, personId);
}

async function deleteOnCall(onCallId, region) {
  const regionSpecificClient = getClientByRegion(region);
  return deleteOnCallInBackend(regionSpecificClient, onCallId);
}

export function deletePerson(personId, region) {
  const regionSpecificClient = getClientByRegion(region);
  return deletePersonInBackend(regionSpecificClient, personId);
}

export function updatePerson(personId, name, number, region) {
  const regionSpecificClient = getClientByRegion(region);
  return updatePersonInBackend(regionSpecificClient, personId, name, number);
}

export function createPerson(name, number, region) {
  const regionSpecificClient = getClientByRegion(region);
  return createPersonInBackend(regionSpecificClient, name, number);
}

export function createOnCall(startDate, endDate, personId, region) {
  const regionSpecificClient = getClientByRegion(region);
  return createOnCallInBackend(
    regionSpecificClient,
    startDate,
    endDate,
    personId
  );
}

export { updateOnCall, getAllOnCall, getAllPerson, deleteOnCall };
