export function getBaseUrl() {
  return process.env.VUE_APP_BASE_URL;
}

export function getRegionBaseUrl(number) {
  return process.env[`VUE_APP_REGION_${number}_URL`];
}

export function getRegion1BaseUrl() {
  return getRegionBaseUrl(1);
}

export function getRegion2BaseUrl() {
  return getRegionBaseUrl(2);
}
export function getRegion3BaseUrl() {
  return getRegionBaseUrl(3);
}
