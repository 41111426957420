import axios from "axios";
import { getRegionBaseUrl } from "@/service/http/urlService";

function generateRegionClient(baseUrl) {
  const accessToken = localStorage.getItem("token");
  return axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

function getClientByRegion(region) {
  return generateRegionClient(getRegionBaseUrl(region));
}

export { getClientByRegion };
