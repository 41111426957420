import {
  parseOnCallFromBackendData,
  parsePeopleFromBackendData
} from "@/service/http/parser";
import OnCall from "@/models/OnCall";
import Person from "@/models/Person";
import moment from "moment";

export function getAllPeopleFromBackend(axiosClient) {
  return new Promise((resolve, reject) => {
    getWithClient(axiosClient, "/person")
      .then(data => {
        resolve(parsePeopleFromBackendData(data));
      })
      .catch(reject);
  });
}

export function getAllOnCallFromBackend(axiosClient) {
  return new Promise((resolve, reject) => {
    getWithClient(axiosClient, "onCall")
      .then(data => {
        resolve(parseOnCallFromBackendData(data));
      })
      .catch(reject);
  });
}

export function updateOnCallInBackend(axiosClient, onCallId, personId) {
  return new Promise((resolve, reject) => {
    putWitClient(axiosClient, `onCall/${onCallId}`, {
      personId: personId
    })
      .then(data => {
        resolve(OnCall.FROM_BACKEND_DATA(data));
      })
      .catch(reject);
  });
}

export async function deleteOnCallInBackend(axiosClient, onCallId) {
  return deleteWithClient(axiosClient, `onCall/${onCallId}`, {});
}

export function createPersonInBackend(axiosClient, name, number) {
  return new Promise((resolve, reject) => {
    postWithClient(axiosClient, "/person", { name: name, number: number })
      .then(resolve)
      .catch(err => {
        reject(err);
      });
  });
}

export function updatePersonInBackend(axiosClient, personId, name, number) {
  return new Promise((resolve, reject) => {
    putWitClient(axiosClient, `/person/${personId}`, {
      name: name,
      number: number
    })
      .then(data => {
        resolve(Person.FROM_BACKEND_DATA(data));
      })
      .catch(reject);
  });
}

export function deletePersonInBackend(axiosClient, personId) {
  return new Promise((resolve, reject) => {
    deleteWithClient(axiosClient, `/person/${personId}`)
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}

export function createOnCallInBackend(
  axiosClient,
  startDate,
  endDate,
  personId
) {
  return new Promise((resolve, reject) => {
    const onCalls = generateOnCallsFromRange(startDate, endDate, personId);
    console.log(onCalls);
    postWithClient(axiosClient, "/onCall", onCalls)
      .then(resolve)
      .catch(reject);
  });
}

// eslint-disable-next-line no-unused-vars
function generateOnCallsFromRange(startDate, endDate, personId) {
  const onCalls = [];
  const endMoment = moment(endDate);
  const runningMoment = moment(startDate);
  while (runningMoment.isSameOrBefore(endMoment)) {
    onCalls.push({
      day: runningMoment.format("YYYY-MM-DD"),
      personId: personId
    });
    runningMoment.add(1, "days");
  }
  return onCalls;
}

function getWithClient(client, url) {
  return new Promise((resolve, reject) => {
    client
      .get(url)
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
}

function putWitClient(client, url, data) {
  return new Promise((resolve, reject) => {
    client
      .put(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
}

function deleteWithClient(client, url) {
  return new Promise((resolve, reject) => {
    client
      .delete(url)
      .then(resolve())
      .catch(reject);
  });
}

function postWithClient(client, url, data) {
  return new Promise((resolve, reject) => {
    client
      .post(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
