import Person from "@/models/Person";
import OnCall from "@/models/OnCall";

export function parsePeopleFromBackendData(data) {
  const peopleArray = [];
  data.forEach(person => {
    peopleArray.push(new Person(person.id, person.name, person.number));
  });
  return peopleArray;
}

export function parseOnCallFromBackendData(data) {
  const onCallArray = [];
  data.forEach(onCall => {
    onCallArray.push(
      new OnCall(onCall.id, new Date(onCall.day), onCall.personId)
    );
  });
  return onCallArray;
}
