// eslint-disable-next-line no-unused-vars
import moment from "moment";

function getWholeOnCall(onCallList, id) {
  const onCallClone = [...onCallList];
  sortOnCallArray(onCallClone);
  const idInList = onCallClone.findIndex(onCall => onCall.id === id);
  const entryOnCall = onCallClone[idInList];
  const samePersonId = [entryOnCall];
  // look forward
  for (
    let runningIndex = idInList + 1;
    runningIndex < onCallClone.length ;
    runningIndex++
  ) {
    if (
      onCallClone[runningIndex].personId === entryOnCall.personId &&
      areOnCallsNextToEachOther(
        onCallClone[runningIndex - 1],
        onCallClone[runningIndex]
      )
    ) {
      samePersonId.push(onCallClone[runningIndex]);
    } else {
      break;
    }
  }
  // look backward
  for (let runningIndex = idInList - 1; runningIndex >= 0; runningIndex--) {
    console.log(onCallClone[runningIndex]);
    if (
      onCallClone[runningIndex].personId === entryOnCall.personId &&
      areOnCallsNextToEachOther(
        onCallClone[runningIndex],
        onCallClone[runningIndex + 1]
      )
    ) {
      samePersonId.push(onCallClone[runningIndex]);
    } else {
      break;
    }
  }
  console.log(samePersonId);
  return samePersonId;
}

function sortOnCallArray(onCallArray) {
  onCallArray.sort((a, b) => a.date - b.date);
}

function areOnCallsNextToEachOther(first, second) {
  return (
    momentDifferenceInDays(moment(first.date), moment(second.date)) === 1 ||
    momentDifferenceInDays(moment(first.date), moment(second.date)) === -1
  );
}

function momentDifferenceInDays(first, second) {
  return moment.duration(first.diff(second)).asDays();
}
export { getWholeOnCall };
